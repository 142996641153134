import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const PARAMETER_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/force_cap/search`;
const PARAMETER_UPDATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/force_cap/search_update`;
const PARAMETER_DELETE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/force_cap/search_delete`;

export const ParameterSearchRequest = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        PARAMETER_SEARCH_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    );
}

export const ParameterUpdateRequest = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        PARAMETER_UPDATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    );
}

export const ParameterDeleteRequest = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        PARAMETER_DELETE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    );
}
