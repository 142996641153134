import axios from "axios";
import { getToken } from "./../../TokenAPI";
const ASSOCIATION_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/association/search`;

export const AssociationSearchApi = async (requestBody) => {

  const bearerToken = await getToken();

  return axios.post(
    ASSOCIATION_SEARCH_ENDPOINT, 
      requestBody,
  {
      headers: {  
          'Cache-Control': 'no-cache',  
          'Pragma': 'no-cache',  
          'Authorization': `Bearer ${bearerToken}`
          
        },
        validateStatus: function (status) {
          return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
        },
  })
}