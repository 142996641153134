import axios from "axios";
import { getToken } from "../../TokenAPI.js";
const ADMIN_CODES_UPDATE = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/update`;

const bearerToken = await getToken();
const headers = {
    'Authorization': `Bearer ${bearerToken}`
  }

export const adminCodesUpdateAxiosClient = axios.create({
  baseURL: ADMIN_CODES_UPDATE,
  headers: headers,
});
