import axios from 'axios';
import { getToken } from '../../../TokenAPI.js';
const FACTORS_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/premium/search`;
const FACTORS_UPDATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/premium/update`;

export const FactorsSearchApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        FACTORS_SEARCH_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}

export const FactorsUpdateApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        FACTORS_UPDATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}