import axios from 'axios';
import { getToken } from "../TokenAPI";
const REPORTS_CYC_NBR_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/reports/member/cyc_nbr`;

export const ReportCycleRefApi = async (model_id, cyc_number, proc_yr_mo) => 
  {
    const requestBody = {      
      model_id: model_id,
      cyc_number: cyc_number,
      proc_yr_mo: proc_yr_mo
    };  

    const bearerToken = await getToken();

    try {
    const response = await axios.post(
      REPORTS_CYC_NBR_ENDPOINT,
      requestBody,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Authorization': `Bearer ${bearerToken}`
        },
      }
    );
    // console.log(response); // This will print the entire response object    
    // console.log(response.status); // This will print the status code    
    // console.log(response.data); // This will print the response data    
    return response;
  } catch (error) {
    console.error(error);
  }    
  };



