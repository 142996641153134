import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const CONTRACT_PROVIDERCONTRACTSEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/providerContractSearch`;

export const providerContractSearchApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_PROVIDERCONTRACTSEARCH_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}