import axios from "axios";
import { getToken } from "../../TokenAPI.js";

const bearerToken = await getToken();
const headers = {
    'Authorization': `Bearer ${bearerToken}`
  }
const DATA_SEG_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg`;
export const dataSegAxiosClient = axios.create({
  baseURL: DATA_SEG_ENDPOINT,
  headers: headers,
});
const ADMIN_PROC_MDL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/admin/group_contracts/search/proc_mdl`;
export const adminProcMdlAxiosClient = axios.create({
  baseURL: ADMIN_PROC_MDL_ENDPOINT,
  headers: headers,
});
const ADMIN_CYC_NBR_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/admin/group_contracts/search/mdl_cyc`;
export const adminMdlCycAxiosClient = axios.create({
  baseURL: ADMIN_CYC_NBR_ENDPOINT,
  headers: headers,
});
const ADMIN_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/admin/group_contracts/search`;
export const adminGroupSearchAxiosClient = axios.create({
  baseURL: ADMIN_SEARCH_ENDPOINT,
  headers: headers,
});
