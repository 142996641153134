import axios from "axios";
import { getToken } from "./TokenAPI.js";

const MEMBER_ROLES_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/search`;


let memberRolesAxiosClient;

async function createAxiosClient() {
  const bearerToken = await getToken();
  memberRolesAxiosClient = axios.create({
    baseURL: MEMBER_ROLES_URL,
    headers: {
      "Authorization": `Bearer ${bearerToken}`,
    },
  });
}

// Call the function to create the Axios client
createAxiosClient();

export { memberRolesAxiosClient };

export var memberRolesJsonSchema = {
  role: [],
};