import axios from 'axios';
import { getToken } from '../../../TokenAPI.js';
const DATA_SEGMENT_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/dataseg`;

export const DataSegmentApi = async () => {
  const bearerToken = await getToken();
    return axios.get(
      DATA_SEGMENT_ENDPOINT,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Authorization': `Bearer ${bearerToken}`
        }
      }
    );
  };



