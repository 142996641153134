import axios from "axios";
import { getToken } from "../../TokenAPI.js";

const MEMBER_ROLES_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/approval/search`;
const MEMBER_ROLES_UPDATE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/approval/update`;

const bearerToken = await getToken();
const headers = {
  'Authorization': `Bearer ${bearerToken}`
}
export const adminApprovalAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_URL,
  headers: headers,
});

export const adminApprovalUpdateAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_UPDATE_URL,
  headers: headers,
});
