import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const CONTRACT_CONTRACT_ADJUSTMENTS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_adjustments`;

export const providerContractAdjustmentApi = async (requestBody) => {

    const bearerToken = await getToken();

    return axios.post(
        CONTRACT_CONTRACT_ADJUSTMENTS_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}