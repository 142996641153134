import axios from "axios";
import { getToken } from "../../../TokenAPI";
const ADJUSTMENTS_UPLOAD_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/payment_adjustment/upload`;

export const adjustmentsUploadApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        ADJUSTMENTS_UPLOAD_ENDPOINT, 
        requestBody,
    {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Authorization': `Bearer ${bearerToken}`
        }
    })
}