import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Select, Skeleton, MenuItem } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import FilterListIcon from "@mui/icons-material/FilterList";
import Tooltip from "@mui/material/Tooltip";
import { getToken } from "../../TokenAPI.js";
import { CodesSearchApi } from "./CodesSearchApi";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import CodesSearchTable from "./CodesSearchTable";
import {
  adminCodesGetSearchAxiosClient,
  adminCodesSearchAxiosClient,
} from "./CodesSearchApi";
import { set } from "react-hook-form";

export const CodesSearch = () => {
  const [CodeType, setCodeType] = useState("");
  const [Code, setCode] = useState("");
  const [Status, setStatus] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const handleCodeTypeChange = (event) => {
    setCodeType(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const [showTable, setShowTable] = useState(false); // State to control table visibility
  const [tableData, setTableData] = useState();
  const [tableLoading, setTableLoading] = useState(true); // State to control loading spinner visibility

  // error messages when none of the fields are filled in

  const [errorMessage, setErrorMessage] = useState("");
  const bearerToken = getToken();
  const headers = {
      'Authorization': `Bearer ${bearerToken}`
    }

  const handleSubmit = () => {
    if (CodeType === "") {
      setErrorMessage("Code Type is required");
      setShowTable(false);
    } else {
      setErrorMessage("");
      setShowTable(true);
      const requestBody = {
        ecap_type_code: CodeType,
        ecap_code: Code,
        active_ind: Status,
      };
      setTableLoading(true);
      adminCodesSearchAxiosClient.post("", requestBody, headers).then((response) => {
        const data = response.data;
        // console.log(data);
        setTableData(data);
        setTableLoading(false);
      });
    }
  };

  useEffect(() => {
    if (tableData !== undefined) {
      // eslint-disable-next-line
      if (tableData != [] && tableData.length > 0) {
        setShowTable(true);
      } else {
        setShowTable(false);
        if (CodeType !== "" || Code !== "" || Status !== "") {
          setErrorMessage("No records found");
        }
      }
    }
  }, [tableData]);
  useEffect(() => {
    adminCodesGetSearchAxiosClient
      .post("", {}, headers)
      .then((response) => {
        const data = response.data;
        const options = data.map((obj) => obj.ECAP_TYP_CD); // Extracting the ECAP_TYP_CD values
        setOptions(options);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleReset = () => {
    // Clear all search fields
    setCodeType("");
    setCode("");
    setStatus("");
    setErrorMessage("");
    // Hide the table if it's currently being shown
    setShowTable(false);
  };

  function toggleSearch() {
    if (showSearch) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  }

  return (
    <div
      style={{
        width: "calc(98.5vw)",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        overflowX: "hidden",
      }}
    >
      {showSearch && (
        <Box sx={{ flexDirection: "column", width: "315px", display: "block" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 315,
              justifyContent: "flex-end",
              alignItems: "right",
              ml: 1,
              mt: 1,
              mb: 1,
            }}
          >
            <Tooltip title='Advanced Filters'>
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Settings'>
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Saved Search'>
              <IconButton>
                <BookmarkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Reset All'>
              <IconButton onClick={() => handleReset()}>
                <ReplayIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Minimize Search'>
              <IconButton onClick={() => toggleSearch()}>
                <KeyboardArrowLeft />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1, whiteSpace: "nowrap" }}
            >
              CodeType<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
              {loading ? (
                <Skeleton
                  animation='wave'
                  variant='text'
                />
              ) : (
                <Select
                  size='small'
                  onChange={handleCodeTypeChange}
                  style={{ width: "100%" }}
                  value={CodeType}
                  id='CodeTypeInput'
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}{" "}
                </Select>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1, whiteSpace: "nowrap" }}
            >
              {" "}
              Code
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
              <TextField
                required
                id='CodeInput'
                value={Code}
                onChange={handleCodeChange}
                fullWidth
                size='small'
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1 }}
            >
              Status
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
              <Select
                required
                id='StatusInput'
                value={Status}
                onChange={handleStatusChange}
                fullWidth
                size='small'
              >
                <MenuItem
                  key='ALL'
                  value='ALL'
                >
                  ALL
                </MenuItem>
                <MenuItem
                  key='N'
                  value='N'
                >
                  INACTIVE
                </MenuItem>
                <MenuItem
                  key='Y'
                  value='Y'
                >
                  ACTIVE
                </MenuItem>
              </Select>
            </Box>
          </Box>
          <Button
            size='medium'
            variant='contained'
            endIcon={<SendIcon />}
            onClick={handleSubmit}
          >
            Search
          </Button>
        </Box>
      )}
      {!showSearch && (
        <div>
          <Tooltip title='Maximize Search'>
            <IconButton onClick={() => toggleSearch()}>
              <KeyboardArrowRight />
            </IconButton>
          </Tooltip>
        </div>
      )}

      {showTable && (
        <div
          style={{
            marginLeft: "1rem",
            width: "100%",
            overflowX: "scroll",
            marginTop: "3.5rem",
          }}
        >
          <CodesSearchTable
            showTable='true'
            tableData={tableData}
            loading={tableLoading}
          />
        </div>
      )}
      {errorMessage && (
        <div style={{ width: "100%", textAlign: "center", color: "red" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
