import axios from 'axios';
import { getToken } from "../TokenAPI";
const CONTRACT_DETAIL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/contract_detail`;

export async function errorContractDetailApi(requestBody) {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_DETAIL_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    );
};