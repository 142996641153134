import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const PROVIDER_HISTORY_TOTALCAP_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/history/total_cap`;
// SearchHandler.js
export const HistoryTotalCapApi = async (
  PROC_YR_MO,
  CONTR_ID,
  CONTR_LN_ID,
  PAY_PARTN_ID,
  CONTR_PARTN_ID,
  ASSOC_PROV_ID


) => {
  // Prepare request body
  const requestBody = {
    PROC_YR_MO: PROC_YR_MO,
    CONTR_ID: CONTR_ID,
    CONTR_LN_ID: CONTR_LN_ID ,
    PAY_PARTN_ID: PAY_PARTN_ID ,
    CONTR_PARTN_ID: CONTR_PARTN_ID,
    ASSOC_PROV_ID: ASSOC_PROV_ID
  };

  const bearerToken = await getToken();

  return axios.post(
    PROVIDER_HISTORY_TOTALCAP_ENDPOINT,
    requestBody,
    {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Authorization': `Bearer ${bearerToken}`
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
};
