import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const CONTRACT_CONTRACT_LINE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_line`;
const CONTRACT_CONTRACT_SUBLINE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_sub_line`;
const CONTRACT_CONTRACT_CLMPOOL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_clm_pool`;

export const contractLineApi1 = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_CONTRACT_LINE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    ).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
    });
};

export const contractLineApi2 = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_CONTRACT_SUBLINE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    ).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
    });
};

export const contractLineApi3 = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_CONTRACT_CLMPOOL_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    ).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
    });
};