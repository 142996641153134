import axios from "axios";
import { getToken } from "../../TokenAPI";
const DAILYINTERFACE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/interface`;
const DAILYINTERFACE_LIST_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/interface_list`;
const DAILYINTERFACE_DETAIL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/interface_detail`;

export const InterfaceApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        DAILYINTERFACE_ENDPOINT, 
        requestBody,
    {
        headers: {  
            'Cache-Control': 'no-cache',  
            'Pragma': 'no-cache',  
            'Authorization': `Bearer ${bearerToken}`
          },
          validateStatus: function (status) {
            return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
          },
    })
}

export const InterfaceListApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(
      DAILYINTERFACE_LIST_ENDPOINT, 
      requestBody,
  {
      headers: {  
          'Cache-Control': 'no-cache',  
          'Pragma': 'no-cache',  
          'Authorization': `Bearer ${bearerToken}`
        },
        validateStatus: function (status) {
          return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
        },
  })
}

export const InterfaceDetailApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(
      DAILYINTERFACE_DETAIL_ENDPOINT, 
      requestBody,
  {
      headers: {  
          'Cache-Control': 'no-cache',  
          'Pragma': 'no-cache',  
          'Authorization': `Bearer ${bearerToken}`
        },
        validateStatus: function (status) {
          return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
        },
  })
}



