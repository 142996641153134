import axios from "axios";
import { getToken } from "./TokenAPI.js";

const CONTRACT_MPIN_API = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/popup_contr_id`;

export const contractMpinApi = async (requestBody) => {
    const token = await getToken();
    return axios.post(
        CONTRACT_MPIN_API, 
        requestBody,
    {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Authorization': `Bearer ${token}`
        }
    })
}