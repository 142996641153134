import axios from "axios";
import { getToken } from "../../TokenAPI.js";

const MEMBER_ROLES_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/search`;
const MEMBER_ROLES_UPDATE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/roles/update`;

const bearerToken = await getToken();
const headers = {
    'Authorization': `Bearer ${bearerToken}`
  }

export const memberRolesAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_URL,
  headers: headers,
});
export var memberRolesJsonSchema = {
  role: [],
};
export const memberRolesUpdateAxiosClient = axios.create({
  baseURL: MEMBER_ROLES_UPDATE_URL,
  headers: headers,
});
