import axios from 'axios';

const TOKEN_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/token`;

// Function to retrieve token from backend and store it in sessionStorage
export const fetchToken = async () => {
    const params = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        tenant_id: process.env.REACT_APP_AZURE_TENANT_ID,
        scope: process.env.REACT_APP_SCOPE
    };
    var token = '';
    try {
        await axios.post(
            TOKEN_ENDPOINT,
            params
        ).then(response => {
            const exipirationTime = response.data.expires_in;
            token = response.data.access_token;
            localStorage.setItem('tokenExpirationTime', exipirationTime * 1000 + Date.now());
            localStorage.setItem('bearerToken', token);
        }).catch(error => {
            console.log('Error fetching token:', error);
        });
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
    return token;
};

// Function to get token from sessionStorage or fetch a new one if not present
export const getToken = async () => {
    let token = localStorage.getItem('bearerToken');
    if (!token || Date.now() > localStorage.getItem('tokenExpirationTime')) {
        token = await fetchToken();
    }
    else {
        console.log('Using cached token');
    }
    return token;
};