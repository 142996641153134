import axios from "axios";
import { getToken } from "../../TokenAPI.js";
const ADMIN_CODES_GET_SEARCH = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/getsearch`;
const ADMIN_CODES_SEARCH = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/search`;
const ADMIN_CODES_ADD = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/add`;
const ADMIN_CHECK_UPDATE = `${process.env.REACT_APP_BACKEND_URL}/admin/codes/upload`;

const bearerToken = await getToken();
const headers = {
    'Authorization': `Bearer ${bearerToken}`
  }

export const adminCodesGetSearchAxiosClient = axios.create({
  baseURL: ADMIN_CODES_GET_SEARCH,
  headers: headers,
});
export const adminCodesSearchAxiosClient = axios.create({
  baseURL: ADMIN_CODES_SEARCH,
  headers: headers,
});
export const adminCodesAddAxiosClient = axios.create({
  baseURL: ADMIN_CODES_ADD,
  headers: headers,
});
export const admincheckupdateAxiosClient = axios.create({
  baseURL: ADMIN_CHECK_UPDATE,
  headers: headers,
});
