import axios from 'axios';  
import { getToken } from "../../../TokenAPI";
const MEMBER_HISTORY_COVERAGE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/coverage`;
  
// SearchHandler.js  
export const MemberCoverageHistoryApi = async (  
  pay_partition_id,  
  ecap_mbr_key,  
 
  
) => {  
  // Prepare request body  
  const requestBody = {  
    pay_partition_id: pay_partition_id,   
    ecap_mbr_key: ecap_mbr_key,  
  };  
  
  const bearerToken = await getToken();

  return axios.post(  
    MEMBER_HISTORY_COVERAGE_ENDPOINT,  
    requestBody,  
    {  
      headers: {  
        'Cache-Control': 'no-cache',  
        'Pragma': 'no-cache',  
        'Authorization': `Bearer ${bearerToken}`
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
      },
    }
  );
}; 

