import axios from 'axios';
import { getToken } from '../../../TokenAPI.js';
const OVERRIDE_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/search`;
const DOCUMENT_UPDATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/search/document`;
const OVERRIDE_UPDATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/update`;
const OVERRIDE_UPDATE_SAVE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/reporting_overrides/search/update_save`;

export const ReportOverrideApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        OVERRIDE_SEARCH_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}

export const ReportOverrideDocumentApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        DOCUMENT_UPDATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}


export const ReportOverrideUpdateApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        OVERRIDE_UPDATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}

export const ReportOverrideUpdateSaveApi = async(requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        OVERRIDE_UPDATE_SAVE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}