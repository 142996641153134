import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePermission } from "./Home/redux/slices/permissionSlice";
import { addRole } from "./Home/redux/slices/roleSlice";
import { setUsername, setName } from "./Home/redux/slices/userInfoSlice";
import { Row } from "react-bootstrap";
import Home from "./Home/Home";
import { memberRolesAxiosClient, memberRolesJsonSchema } from "./userInfoApi";
import { viewMembersJsonSchema } from "./MemberTab/MemberSearchTab/MemberSearchConstant";
import { CircularProgress } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { getToken } from "./TokenAPI.js";

const Login = ({ onLoginSuccess }) => {
  const dispatch = useDispatch();
  const [loginFailed, setLoginFailed] = useState(false);
  useEffect(() => {
    const isredirected = localStorage.getItem("isredirected");

    const urlParams = new URLSearchParams(window.location.search);
    const authorizationcode = urlParams.get("code");
    const clientID = "Reg1Dev_ecap-gui";
    const redirectURL = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    const clientSecret = "q3&oqe6M4NgqMTMqHMBhC8kQxCHVitDuVf&Vq#R8$$aT";
    console.log(isredirected);
    if (authorizationcode && isredirected !== null) {
      fetch(
        process.env.REACT_APP_API_AUTH +
          "/oauth2/callback?code=" +
          authorizationcode +
          "&clientID=" +
          clientID +
          "&redirect_url=" +
          redirectURL +
          "&clientSecret=" +
          clientSecret,
        {
          method: "POST",
        }
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.error) {
            console.error("Error occured during SSO callback:", data.error);
          } else {
            const username = data.userData.sub;
            var roles = data.userData.roles;
            // roles = ["ecapnon"];
            if (roles.length === 0 || roles[0] === "") {
              setLoginFailed(true);
              return;
            }
            const name = data.userData.name;
            dispatch(setName(name));
            dispatch(setUsername(username));
            const requestBody = { ...memberRolesJsonSchema };
            requestBody.role = roles;
            roles.map((role) => {
              dispatch(addRole(role));
            });
            localStorage.setItem("sub", data.userData.sub);
            localStorage.setItem("accessToken", data.userData.accessToken);
            console.log(localStorage.getItem("sub"));
            const bearerToken = await getToken();
            memberRolesAxiosClient
              .post("", requestBody,
                {
                  headers: {
                    'Authorization': `Bearer ${bearerToken}`
                  },
                }
              )
              .then((response) => {
                data = response.data;
                console.log(data);
                Object.keys(data).map((screenName) => {
                  Object.keys(data[screenName]).map((action) => {
                    const value = data[screenName][action];
                    const permission = screenName;
                    const field = action;
                    dispatch(updatePermission({ permission, field, value }));
                  });
                });
                onLoginSuccess(true);
                localStorage.setItem("isredirected", "true");
              })
              .catch((error) => {
                if (error.response && error.response.status === 500) {
                  console.log("error logging you in ");
                  setLoginFailed(true);
                }
              });
          }
        })
        .catch((error) => {
          console.error("Error occured during SSO callback 2 :", error);
          setLoginFailed(true);
        });
    } else {
      redirectToSSO();
    }
  }, []);

  const redirectToSSO = () => {
    console.log("Hi");
    //Redirect the user to SSO flow
    const clientID = "Reg1Dev_ecap-gui";
    const redirectURL = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    const scope = "openid%20profile%20address%20email%20phone";
    console.log(clientID);
    try {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_AUTH, // Set your base URL here
      });
      axiosInstance
        .get("/getURL", {
          params: {
            clientID: clientID,
            redirect_url: redirectURL,
            scope: scope,
          },
        })
        .then((response) => {
          console.log(response);
          if (!response.status === 200) {
            throw new Error("Failed to fetch SSO URL");
          }
          return response.data;
        })
        .then((data) => {
          console.log(data);

          localStorage.setItem("isredirected", "true");
          window.location.href = data;
        });
    } catch (error) {
      console.error("Error occured during sso login: ", error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      {loginFailed ? (
        <>
          <h1 style={{ textAlign: "center", marginBottom: "16px" }}>
            Access Denied
          </h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ClearIcon
              color='error'
              style={{ fontSize: "15rem", marginRight: "8px" }}
            />
          </div>
          <h6>
            plase contact Ecap It Team to get access or try to reload the
            website
          </h6>
        </>
      ) : (
        <>
          <h1 style={{ textAlign: "center", marginBottom: "16px" }}>
            Authenticating
          </h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircularProgress />
          </div>
        </>
      )}
    </div>
  );
};
export default Login;
