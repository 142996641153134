import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Select, Skeleton, MenuItem } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import FilterListIcon from "@mui/icons-material/FilterList";
import Tooltip from "@mui/material/Tooltip";
import { testPDSeg } from '../../testPopupDataSegment';
import { getToken } from "../../TokenAPI.js";
import {
  dataSegAxiosClient,
  adminProcMdlAxiosClient,
  adminMdlCycAxiosClient,
  adminGroupSearchAxiosClient,
} from "./GroupContractsApi";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import GroupContractsTable from "./GroupContractsTable";
import { set } from "react-hook-form";

export const AdminGroupContracts = (inputDataSegments) => {
  const bearerToken = getToken();
  const headers = {
      'Authorization': `Bearer ${bearerToken}`
    }
  const [dataSegment, setDataSegment] = useState("");
  const [Model, setModel] = useState("");
  const [Cycle, setCycle] = useState("");
  const [Contract, setContract] = useState("");
  const [ContractOwnerMPIN, setContractOwnerMPIN] = useState("");
  const [showSearch, setShowSearch] = useState(true);

  const handleCycleChange = (event) => {
    setCycle(event.target.value);
  };

  const handleContractChange = (event) => {
    setContract(event.target.value.replace(/\D/g, ""));
  };
  const [loading, setLoading] = useState(true);
  const [segmentMapping, setSegmentMapping] = useState([]);
  useEffect(() => {
    dataSegAxiosClient.get("", headers).then((response) => {
      console.log(response.data);
      setSegmentMapping(response.data);
      setLoading(false);
    });
  }, []);
  const handleContractOwnerMPINChange = (event) => {
    setContractOwnerMPIN(event.target.value.replace(/\D/g, ""));
  };

  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  // error messages when none of the fields are filled in

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (dataSegment === "" && Model === "" && Cycle === "") {
      setErrorMessage(
        "Fill in at least one set of the following fields: Contract ID and Contract Line ID --- OR --- Associate Contract ID and Associate Contract Line ID"
      );
      setShowTable(false);
    } else {
      setErrorMessage("");
      const dataSegId = segmentMapping.filter(
        (seg) => seg.NAME === dataSegment
      );
      setTableLoading(true);
      const requestBody = {
        data_seg: dataSegId[0].DATA_SEG_ID,
        cyc_number: Cycle,
        mpin: ContractOwnerMPIN,
        contr_id: Contract,
      };
      adminGroupSearchAxiosClient.post("", requestBody, headers).then((response) => {
        const data = response.data;
        setTableData(data);
        setTableLoading(false);
      });
      setShowTable(true);
    }
  };

  useEffect(() => {
    if (tableData !== undefined) {
      // eslint-disable-next-line
      if (tableData != [] && tableData.length > 0) {
        setShowTable(true);
      } else {
        setShowTable(false);
      }
    }
  }, [tableData]);

  const handleReset = () => {
    // Clear all search fields
    setDataSegment("");
    setModel("");
    setCycle("");
    setContract("");
    setContractOwnerMPIN("");

    // Hide the table if it's currently being shown
    setShowTable(false);
  };

  function toggleSearch() {
    if (showSearch) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  }
  const [modelOptions, setModelOptions] = useState([]);
  const dataSegmentChange = (event) => {
    const selectedSegment = event.target.value;

    setLoading(true);
    setDataSegment(selectedSegment);
    const dataSegId = segmentMapping.filter(
      (seg) => seg.NAME === selectedSegment
    );
    const requestBody = {
      data_seg: dataSegId[0].DATA_SEG_ID,
    };

    adminProcMdlAxiosClient.post("", requestBody, headers).then((response) => {
      const data = response.data;
      const temp = [];
      data.map((model) => {
        temp.push(model.MDL_NM);
      });
      console.log(data);
      setModel("");
      setModelOptions(temp);
      setLoading(false);
    });
  };
  function openPopupDataSegment() {
    testPDSeg('data-seg-select');
    window.onmessage = (event) => {
        try {
            var response = event.data.split(' ');
            setDataSegment(response[1]);
        } catch {
            console.log('popup receive message error');
        }
    };
};

  const [cycOptions, setCycOptions] = useState([]);
  const handleModelChange = (event) => {
    setModel(event.target.value);
    setLoading(true);
    const dataSegId = segmentMapping.filter((seg) => seg.NAME === dataSegment);
    const requestBody = {
      data_seg: dataSegId[0].DATA_SEG_ID,
    };
    adminMdlCycAxiosClient.post("", requestBody, headers).then((response) => {
      const data = response.data;
      console.log(data);
      const temp = [];
      data.map((model) => {
        temp.push(model.CYC_NBR);
      });
      console.log(data);
      setCycle("");
      setCycOptions(temp);
      setLoading(false);
    });
  };

  return (
    <div
      style={{
        width: "calc(98.5vw)",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        overflowX: "hidden",
      }}
    >
      {showSearch && (
        <Box sx={{ flexDirection: "column", width: "315px", display: "block" }}>
          <br />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: 315,
              justifyContent: "flex-end",
              alignItems: "right",
              ml: 1,
              mt: 1,
              mb: 1,
            }}
          >
            <Tooltip title='Advanced Filters'>
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Settings'>
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Saved Search'>
              <IconButton>
                <BookmarkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Reset All'>
              <IconButton onClick={() => handleReset()}>
                <ReplayIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Minimize Search'>
              <IconButton onClick={() => toggleSearch()}>
                <KeyboardArrowLeft />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1, whiteSpace: "nowrap" }}
            >
              Data Segment<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 138 }}>
              {loading ? (
                <Skeleton
                  animation='wave'
                  variant='text'
                />
              ) : (
                <Select
                  size='small'
                  onChange={(event) => dataSegmentChange(event)}
                  style={{ width: "100%" }}
                  value={dataSegment}
                  id='data-seg-select'
                >
                  {segmentMapping.map((segment) => (
                    <MenuItem
                      key={segment.DATA_SEG_ID}
                      value={segment.NAME}
                    >
                      {segment.NAME}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <div
              style={{
                height: "40px",
                width: "40px",
                border: "#d3d3d3 1px solid",
                borderRadius: "3px",
                textAlign: "center",
                alignContent: "center",
                marginLeft: "0.2rem",
              }}
            >
              <img
                style={{ height: "80%", width: "80%" }}
                src='Binoculars-icon.png'
                title='Filtering Data segment'
                onClick={() => openPopupDataSegment()}
              ></img>
            </div>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1, whiteSpace: "nowrap" }}
            >
              {" "}
              Model<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
              {loading ? (
                <Skeleton
                  animation='wave'
                  variant='text'
                />
              ) : (
                <Select
                  required
                  id='ModelInput'
                  value={Model}
                  onChange={handleModelChange}
                  fullWidth
                  size='small'
                >
                  {modelOptions.map((name) => (
                    <MenuItem value={name}>{name}</MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1 }}
            >
              Cycle<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
              {loading ? (
                <Skeleton
                  animation='wave'
                  variant='text'
                />
              ) : (
                <Select
                  required
                  id='CycleInput'
                  value={Cycle}
                  onChange={handleCycleChange}
                  fullWidth
                  size='small'
                >
                  {" "}
                  {cycOptions.map((name) => (
                    <MenuItem value={name}>{name}</MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1, whiteSpace: "nowrap" }}
            >
              Contract#
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
              <TextField
                id='ContractInput'
                value={Contract}
                onChange={handleContractChange}
                fullWidth
                size='small'
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
            <Typography
              variant='body2'
              sx={{ fontWeight: "bold", flex: 1, ml: 1, whiteSpace: "nowrap" }}
            >
              <Box>Contract Owner</Box>
              <Box sx={{ whiteSpace: "nowrap" }}>MPIN</Box>
            </Typography>
            <Box sx={{ flex: 2, minWidth: 80, maxWidth: 180 }}>
              <TextField
                required
                id='ContractOwnerMPINInput'
                value={ContractOwnerMPIN}
                onChange={handleContractOwnerMPINChange}
                fullWidth
                size='small'
              />
            </Box>
          </Box>
          <Button
            size='medium'
            variant='contained'
            endIcon={<SendIcon />}
            onClick={handleSubmit}
          >
            Search
          </Button>
        </Box>
      )}
      {!showSearch && (
        <div>
          <Tooltip title='Maximize Search'>
            <IconButton onClick={() => toggleSearch()}>
              <KeyboardArrowRight />
            </IconButton>
          </Tooltip>
        </div>
      )}

      {showTable && (
        <div
          style={{
            marginLeft: "1rem",
            width: "100%",
            overflowX: "scroll",
            marginTop: "3.5rem",
          }}
        >
          <GroupContractsTable
            showTable='true'
            inputTableData={tableData}
            tableLoading={tableLoading}
            modelOptions={modelOptions}
            cycOptions={cycOptions}
          />
        </div>
      )}
      {errorMessage && (
        <div style={{ width: "100%", textAlign: "center", color: "red" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};
