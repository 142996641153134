import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import { getToken } from "../../TokenAPI.js";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import {
  adminExcludeCheckAxiosClient,
  adminExcludeAxiosClient,
} from "./ExcludeContractsApi";

const ExcludeContractsTable = ({
  showTable,
  inputTableData,
  tableLoading,
  setErrorMessage,
  handleReset,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState([]);
  const permissions = useSelector((store) => store.user.permissions);
  const updatePermission =
    permissions.admin_exclude.update === "Y" ? true : false;

  useEffect(() => {
    if (inputTableData) {
      setTableData(inputTableData);
    }
  }, [inputTableData]);
  const [checkedRows, setCheckedRows] = useState([]);
  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, row]);
    } else {
      setCheckedRows(checkedRows.filter((checkedRow) => checkedRow !== row));
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!showTable) {
    return null;
  }
  const exclude = async () => {
    const bearerToken = await getToken();
    const headers = {
        'Authorization': `Bearer ${bearerToken}`
      }
    console.log("exclude");
    let requests = []; // Declare the 'requests' variable

    checkedRows.map((row) => {
      var requestBody = {
        data_seg: row.MDL_ID,
        cyc_nbr: row.CYC_NBR,
      };
      const request = adminExcludeCheckAxiosClient
        .post("", requestBody, headers)
        .then((response) => {
          const data = response.data;
          const count = data[0].count;
          if (count === "0") {
            setErrorMessage("");
            requestBody = {
              contr_key: row.ECAP_CONTR_LN_KEY,
            };
            return adminExcludeAxiosClient
              .post("", requestBody, headers)
              .then((response) => {
                const data = response.data;
                console.log(data);
                return true;
              });
          } else {
            setErrorMessage("Cycle is already running, can't make updates");
            return false;
          }
        });

      requests.push(request); // Add the request to the 'requests' array
    });

    Promise.all(requests).then((results) => {
      if (results.includes(true)) {
        setErrorMessage("Contracts excluded successfully");
        handleReset();
      }
    });
  };

  return (
    <>
      <Paper
        sx={{
          paddingLeft: "0.2rem",
          boxShadow: "none",
          width: "100%",
          paddingTop: "1.6rem",
          overflow: "hidden",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ width: "100%" }}
          sx={{ maxHeight: "70vh" }}
        >
          <Table
            aria-label='simple table'
            stickyHeader
            style={{ width: "100%" }}
          >
            <TableHead style={{ backgroundColor: "#b0c0da" }}>
              <TableRow style={{ backgroundColor: "#b0c0da" }}>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Exclude
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Contract
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Contract Line
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  MPIN
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Model
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Cycle
                </TableCell>
                <TableCell
                  style={{ backgroundColor: "#b0c0da", textAlign: "center" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableLoading ? (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Skeleton
                      animation='wave'
                      variant='text'
                    />
                  </TableCell>
                </TableRow>
              ) : (
                tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isChecked = checkedRows.includes(row);
                    return (
                      <TableRow key={row.CONTR_SRC_SYS_CD}>
                        <TableCell style={{ textAlign: "center" }}>
                          <input
                            type='checkbox'
                            checked={isChecked}
                            onChange={(event) =>
                              handleCheckboxChange(event, row)
                            }
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.CONTR_ID}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.CONTR_LN}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.PROV_ID}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.MDL_NM}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.CYC_NBR}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {row.PROC_IND === "Y" ? "	Processed" : "Inprogress"}
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel": {
              paddingTop: "12px",
            },
            ".MuiTablePagination-displayedRows": {
              paddingTop: "13px",
            },
          }}
        />
      </Paper>
      <br/>
      <br/>
      <div
        style={{
          position: "absolute",
          bottom: "1rem",
          right: "1rem",
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          size='medium'
          variant='contained'
          disabled={checkedRows.length === 0 || !updatePermission}
          onClick={exclude}
        >
          Move
        </Button>
      </div>
    </>
  );
};

export default ExcludeContractsTable;
