import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const MEMBER_HISTORY_SEARCH_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/search`;

// SearchHandler.js  
export const SearchApi = async (requestBody) => {
	const bearerToken = await getToken();
	// Prepare request body
	return axios.post(
		MEMBER_HISTORY_SEARCH_ENDPOINT,
		requestBody,
		{
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': `Bearer ${bearerToken}`
			},
			validateStatus: function (status) {
				return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx      
			},
		}
	)
		.then(response => {
			// console.log(response); // This will print the entire response object    
			// console.log(response.status); // This will print the status code    
			// console.log(response.data); // This will print the response data    

			// Return the response so it can be used in handleButtonClick  
			return response;
		})
		.catch(error => {
			console.error(error);
		});
};