import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const CONTRACT_RATE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_rate`;
const CONTRACT_RATE_DETAIL_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_rate_detail`;
const CONTRACT_RATE_SCHDEULE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_rate_schedule`;

export const providerContractRateRegularApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_RATE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}

export const providerContractRateDetailApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_RATE_DETAIL_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}

export const providerContractRateScheduleApi = async (requestBody) => {
    const bearerToken = await getToken();
    return axios.post(
        CONTRACT_RATE_SCHDEULE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}