import axios from "axios";
import { getToken } from "../../TokenAPI";
const MONTHLYPROCESSED_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/monthly`;
const MONTHLYPROCESSED_GROUPBY_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/monthly_group`;
const MONTHLYPROCESSED_LIST_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/errors/monthly_list`;

export const MonthlyApi = async (requestBody) => {
  const bearerToken = await getToken();
    return axios.post(
      MONTHLYPROCESSED_ENDPOINT, 
        requestBody,
    {
        headers: {  
            'Cache-Control': 'no-cache',  
            'Pragma': 'no-cache',  
            'Authorization': `Bearer ${bearerToken}`
          },
          validateStatus: function (status) {
            return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
          },
    })
}

// need to make
export const MonthlyGroupApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(
    MONTHLYPROCESSED_GROUPBY_ENDPOINT, 
      requestBody,
  {
      headers: {  
          'Cache-Control': 'no-cache',  
          'Pragma': 'no-cache',  
          'Authorization': `Bearer ${bearerToken}`
        },
        validateStatus: function (status) {
          return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
        },
  })
}

// need to make
export const MonthlyListApi = async (requestBody) => {
  const bearerToken = await getToken();
  return axios.post(
    MONTHLYPROCESSED_LIST_ENDPOINT, 
      requestBody,
  {
      headers: {  
          'Cache-Control': 'no-cache',  
          'Pragma': 'no-cache',  
          'Authorization': `Bearer ${bearerToken}`
        },
        validateStatus: function (status) {
          return status >= 200 && status < 400; // Reject only if the status code is outside the range of 2xx and 3xx
        },
  })
}