import axios from 'axios';
import { getToken } from "../TokenAPI";
const REPORTS_MDL_ID_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/reports/member/mdl_id`;

export const ReportModelIDApi = async (data_seg) => 
  {
    const requestBody = {      
      data_seg: data_seg
    };  

    const bearerToken = await getToken();

    try {
    const response = await axios.post(
      REPORTS_MDL_ID_ENDPOINT,
      requestBody,
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Authorization': `Bearer ${bearerToken}`
        },
        validateStatus: function (status_1) {
          return status_1 >= 200 && status_1 < 400; // Reject only if the status code is outside the range of 2xx and 3xx      
        },
      }
    );
    // console.log(response); // This will print the entire response object    
    // console.log(response.status); // This will print the status code    
    // console.log(response.data); // This will print the response data    
    return response;
  } catch (error) {
    console.error(error);
  }    
  };



