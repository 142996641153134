import axios from 'axios';
import { getToken } from "../../../TokenAPI";
const CONTRACT_CONTRACT_REQULAR_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/provider/contract/contract_regular`;

export const contractNormalApi = async (requestBody) => {

    const bearerToken = await getToken();

    return axios.post(
        CONTRACT_CONTRACT_REQULAR_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        }
    ).then(response => {
        return response;
    }).catch(error => {
        console.error(error);
    });
};
