import axios from 'axios';
import { getToken } from "../../TokenAPI";
const RETRO_DATASEGMENT_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/retro/rowdata`;
const RETRO_CONFIGURE_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/maintenance/retro/configure`;


export const RetroSegmentApi = async (requestBody) => {
    const bearerToken = await getToken();
    console.log(RETRO_DATASEGMENT_ENDPOINT)
    console.log(requestBody);
    return axios.post(
        RETRO_DATASEGMENT_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}

export const retroConfigureApi = async (requestBody) => {
    const bearerToken = await getToken();
    console.log(RETRO_CONFIGURE_ENDPOINT)
    return axios.post(
        RETRO_CONFIGURE_ENDPOINT,
        requestBody,
        {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
}
