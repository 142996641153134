 import axios from 'axios';  
 import { getToken } from "../../../TokenAPI";
 const MEMBER_HISTORY_DETAILS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/member/history/detail`;
  
// SearchHandler.js  
export const MemberDetailsApi = async (  
  data_segment,
  ecap_mbr_key,
  ecap_contr_ln_key,
  proc_yr_mo,
  cap_prd_yr_mo,

) => {
  // Prepare request body
  const requestBody = {
    data_segment: data_segment,
    ecap_mbr_key: ecap_mbr_key,
    ecap_contr_ln_key: ecap_contr_ln_key,
    proc_yr_mo: proc_yr_mo,
    cap_prd_yr_mo: cap_prd_yr_mo

  };

  const bearerToken = await getToken();

  return axios.post(
    MEMBER_HISTORY_DETAILS_ENDPOINT,
    requestBody,
    {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Authorization': `Bearer ${bearerToken}`
      },
    }
  );
};  

