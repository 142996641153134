import { Box, Button, LinearProgress, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { testPDSeg } from "../../../testPopupDataSegment";
import { retroAddSysDefaultApi, retroAddApi } from "./retroAddApi";
import { getDataSegmentIntFromString } from "../../../Home/dataSegmentApi";

const RetroPeriodAdd = ({
    inputDataSegments,
    switchTabs
}) => {
    const retroTypeOptions = {
        'ADD': 'ADD',
        'CONTRACT CHANGE': 'CONTRCHG',
        'PREMIUM CHANGE': 'PREMCHG',
        'TERM': 'TERM'
    }

    const [selectedDataSegments, setSelectedDataSegments] = useState(Array(10).fill('select'));
    const [selectedRetroTypes, setSelectedRetroTypes] = useState(Array(10).fill('select'));

    const [errorMessages, setErrorMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    function openPopupDataSegment(index) {
        testPDSeg('data-seg-select');
        window.onmessage = (event) => {
            try {
                var response = event.data.split(' ');
                setSelectedDataSegments([
                    ...selectedDataSegments.slice(0, index),
                    response[1],
                    ...selectedDataSegments.slice(index + 1)
                ]);
            } catch {
                console.log('popup receive message error');
            }
        };
    };

    function handleNumberOnly(event, allowFloat) {
        const pattern = /^[0-9\b]+$/; // only allow digits and backspace  
        if (
            !pattern.test(event.key) &&
            event.key !== 'Backspace' &&
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight' &&
            event.key !== 'Tab' &&
            !(event.ctrlKey && event.key === 'v') &&
            !(event.ctrlKey && event.key === 'c') &&
            !(event.metaKey && event.key === 'v') &&
            !(event.metaKey && event.key === 'c')
        ) {
            if (
                allowFloat &&
                event.key !== '.' &&
                !(event.ctrlKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'c')
            ) {
                event.preventDefault();
            } else if (
                !allowFloat &&
                !(event.ctrlKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'v') &&
                !(event.metaKey && event.key === 'c')
            ) {
                event.preventDefault();
            }
        }
    }

    function retroTypeChange(event, index) {
        setSelectedRetroTypes([
            ...selectedRetroTypes.slice(0, index),
            event.target.value,
            ...selectedRetroTypes.slice(index + 1)
        ]);
        var requestBody = {
            "DATA_SEG_ID": getDataSegmentIntFromString(selectedDataSegments[index]),
            "RETRO_TYPE_CD": retroTypeOptions[event.target.value]
        }
        var sysDefault = document.getElementById(index + '_default');
        if (requestBody["DATA_SEG_ID"] && requestBody["RETRO_TYPE_CD"]) {
            retroAddSysDefaultApi([requestBody]).then(response => {
                if (response.data.results.length > 0) {
                    var result = response.data.results[0].split(' ').pop();
                    if (/^[0-9\b]+$/.test(result)) {
                        sysDefault.value = result;
                    } else {
                        sysDefault.value = 'null';
                    }
                } else {
                    sysDefault.value = 'null';
                }
            }).catch(error => {
                sysDefault.value = 'null';
                console.log(error);
            });
        } else {
            sysDefault.value = 'null';
        }
    }

    function handleSave() {
        setErrorMessages([]);
        var tempErrorMessages = [];
        var data = Array(10);
        var rows = [];
        for (let i = 0; i < 10; i++) {
            var temp = {
                "dataSegment": "",
                "retroType": "",
                "begin": "",
                "end": "",
                "max": "",
                "default": ""
            }
            data[i] = temp;
        }
        for (let i = 0; i < 10; i++) {
            data[i]["dataSegment"] = selectedDataSegments[i];
            data[i]["retroType"] = selectedRetroTypes[i];
            data[i]["begin"] = document.getElementById(i + "_begin").value;
            data[i]["end"] = document.getElementById(i + "_end").value;
            data[i]["max"] = document.getElementById(i + "_max").value;
            var temp = document.getElementById(i + "_default");
            if (
                /^\s*$/.test(temp.value) ||
                !/^[0-9\b]+$/.test(temp.value)) {
                temp.value = 0;
            }
            data[i]["default"] = temp.value;
        }
        // booleans for error messages
        var hasNoData = true;
        var missingRetroType = false;
        var missingBegin = false;
        var invalidBegin = false;
        var invalidEnd = false;
        var flippedDates = false;
        var missingMax = false;
        var invalidMax = false;
        for (let i = 9; i >= 0; i--) {
            if (!(
                data[i]["dataSegment"] === 'select'
            )) {
                hasNoData = false;
                var hasIssues = false;
                if (data[i]["retroType"] === 'select') {
                    missingRetroType = true;
                    hasIssues = true;
                }
                if (/^\s*$/.test(data[i]["begin"])) {
                    missingBegin = true;
                    hasIssues = true;
                } else if (parseInt(data[i]["begin"]) < 194001) {
                    invalidBegin = true;
                    hasIssues = true;
                } else if (
                    parseInt(data[i]["begin"].slice(-2)) < 1 ||
                    parseInt(data[i]["begin"].slice(-2)) > 12
                ) {
                    invalidBegin = true;
                    hasIssues = true;
                }
                if (!/^\s*$/.test(data[i]["end"])) {
                    if (parseInt(data[i]["end"]) < 194001) {
                        invalidEnd = true;
                        hasIssues = true;
                    } else if (parseInt(data[i]["begin"]) > parseInt(data[i]["end"])) {
                        flippedDates = true;
                        hasIssues = true;
                    }
                }
                if (/^\s*$/.test(data[i]["max"])) {
                    missingMax = true;
                    hasIssues = true;
                } else if (
                    parseInt(data[i]["max"]) > parseInt(data[i]["default"])
                ) {
                    invalidMax = true;
                    hasIssues = true;
                }
                if (hasIssues) {
                    data = [
                        ...data.slice(0, i),
                        ...data.slice(i + 1)
                    ]
                } else {
                    rows.push(i);
                }
            } else {
                data = [
                    ...data.slice(0, i),
                    ...data.slice(i + 1)
                ]
            }
        }
        if (hasNoData) {
            tempErrorMessages.push('* Please enter at least one row of data.');
        }
        if (missingRetroType) {
            tempErrorMessages.push('* RetroTypeCode is required.');
        }
        if (missingBegin) {
            tempErrorMessages.push('* BeginEffectivePeriod is required.');
        }
        if (invalidBegin) {
            tempErrorMessages.push('* Begin Effective Period is not a valid date');
        }
        if (invalidEnd) {
            tempErrorMessages.push('* End Effective Period is not a valid date');
        } else if (flippedDates) {
            tempErrorMessages.push('* Begin Effective Period should not be greater than End Effective Period.');
        }
        if (missingMax) {
            tempErrorMessages.push('* MaxPeriod is required.');
        } else if (invalidMax) {
            tempErrorMessages.push('* Max Period cannot be greater than the System Default');
        }
        if (tempErrorMessages.length == 0) {
            setLoading(true);
            var requestBody = []
            for (let i = 0; i < data.length; i++) {
                var temp = {
                    'DATA_SEG_ID': getDataSegmentIntFromString(data[i]["dataSegment"]),
                    'BEGIN_EFF': data[i]["begin"],
                    'RETRO_TYPE_CD': retroTypeOptions[data[i]["retroType"]],
                    'BEGIN_END': data[i]["end"],
                    'MAX_PERIOD': data[i]["max"],
                    'UPDT_USER_ID': localStorage.getItem('sub')
                }
                requestBody.push(temp);
            }
            // console.log(requestBody);
            retroAddApi(requestBody).then(response => {
                response = response.data.results;
                rows.reverse();
                for (let i = 0; i < response.length; i++) {
                    tempErrorMessages.push('* Row ' + (rows[i] + 1) + ' is inserted successfully');
                }
                setLoading(false);
                setErrorMessages(tempErrorMessages);
            }).catch(error => {
                // console.log(error);
                if (error.response.data.results) {
                    error = error.response.data.results;
                    rows.reverse();
                    for (let i = 0; i < error.length; i++) {
                        if (error[i].includes('successfully')) {
                            tempErrorMessages.push('* Row ' + (rows[i] + 1) + ' - Inserted successfully');
                        } else if (error[i].includes('overlap')) {
                            tempErrorMessages.push('* Row ' + (rows[i] + 1) + ' - Begin Process Period and End Process Period overlap');
                        } else if (error[i].includes('already exists')) {
                            tempErrorMessages.push('* Row ' + (rows[i] + 1) + ' - Duplicate Record already exists');
                        }
                    }
                    setLoading(false);
                    setErrorMessages(tempErrorMessages);
                } else {
                    setLoading(false);
                    setErrorMessages(['* Error adding records']);
                }
            });
        } else {
            setErrorMessages(tempErrorMessages);
        }
    }

    return (
        <Box sx={{ width: 'calc(100vw - 24px)' }}>
            {errorMessages.length > 0 && errorMessages.map((temp, index) => (
                <Box key={'errorMessage_' + index} sx={{ mb: '1rem', width: '100%', textAlign: 'center', color: 'red' }}>
                    {temp}
                </Box>
            ))}
            {loading && <LinearProgress sx={{ mt: '1rem', width: '100%' }} />}
            <Box sx={{ maxHeight: '60vh', overflow: 'scroll' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data Segment</TableCell>
                            <TableCell>Retro Type</TableCell>
                            <TableCell>Begin Effective Period</TableCell>
                            <TableCell>End Effective Period</TableCell>
                            <TableCell>Max Period</TableCell>
                            <TableCell>System Default</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array(10).fill().map((_, index) => (
                            <TableRow key={'row_' + index}>
                                <TableCell sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Select
                                        value={selectedDataSegments[index]}
                                        sx={{ width: 150 }}
                                        size='small'
                                        onChange={(event) => setSelectedDataSegments([
                                            ...selectedDataSegments.slice(0, index),
                                            event.target.value,
                                            ...selectedDataSegments.slice(index + 1)
                                        ])}
                                    >
                                        <MenuItem
                                            value='select'
                                        >
                                            ---Select---
                                        </MenuItem>
                                        {inputDataSegments.map((row) => (
                                            <MenuItem key={index + '_' + row.DATA_SEG_ID} value={row.NAME}>{row.NAME}</MenuItem>
                                        ))}
                                    </Select>
                                    <Box
                                        sx={{ ml: '0.5rem', padding: '5px', height: '40px', width: '40px', border: '#d3d3d3 1px solid', borderRadius: '3px', textAlign: 'center', alignContent: 'center' }}
                                        onClick={() => openPopupDataSegment(index)}
                                    >
                                        <img
                                            style={{ height: '30px', width: '30px' }}
                                            src="Binoculars-icon.png"
                                            title="Filtering Data segment"
                                        // onClick={() => openPopupDataSegment()}
                                        ></img>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={selectedRetroTypes[index]}
                                        sx={{ width: 200 }}
                                        size='small'
                                        onChange={(event) => retroTypeChange(event, index)}
                                    >
                                        <MenuItem
                                            value='select'
                                        >
                                        </MenuItem>
                                        {Object.keys(retroTypeOptions).map((option) => (
                                            <MenuItem
                                                key={'retroTypeItem_' + option + '_' + index}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_begin'}
                                        style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={6}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_end'}
                                        style={{ minWidth: 50, width: 120, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={6}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_max'}
                                        style={{ minWidth: 50, width: 80, backgroundColor: "beige", borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                        maxLength={2}
                                        onKeyDown={(event) => handleNumberOnly(event, false)}
                                    >
                                    </input>
                                </TableCell>
                                <TableCell>
                                    <input
                                        id={index + '_default'}
                                        disabled={true}
                                        style={{ minWidth: 50, width: 80, borderColor: 'whitesmoke', padding: '5px', borderRadius: '5px' }}
                                    >
                                    </input>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{ mt: '1rem', display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Button variant="contained" onClick={() => handleSave()}>Save</Button>
                <Button variant="contained" size='medium' style={{ backgroundColor: 'red', color: 'white' }} onClick={() => switchTabs(0)}>Cancel</Button>
            </Box>
        </Box >
    )
}

export default RetroPeriodAdd;